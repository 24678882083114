  import React, { useState,useEffect } from 'react';
import CategoryList from '../components/CategoryList';
import ItemList from '../components/ItemList';
import Cart from '../components/Cart'
import { Link,useLocation } from 'react-router-dom';
import Slider from '../components/Slider';
import MyComponent from '../components/MyComponent'


const Catalog = ({ categories, onOpen, isAddedToCart,
   title, cartOpened, onAddToCart, catname, setCartOpened,
    cartItems, setQuantity, setCartItems, quantity, count, increase,showSlider, open,
    setOpen,onClose,
     setCount, onRemove, taste, handleQuantityChange, item }) => {
  
  const [selectedCategory, setSelectedCategory] = useState(1);
  const handleCategoryChange = (id) => {
    setSelectedCategory(id);
  };
  
 const location = useLocation();

  useEffect(() => {
    if (location.hash === '#top') {
      // Найти элемент с id "top" и прокрутить его в видимую область
      const element = document.getElementById('top');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Set isMobile state based on screen width
    };

    handleResize(); // Call initially to set the initial state
    window.addEventListener('resize', handleResize); // Add event listener for resize

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup event listener
    };
  }, []);

  let data = [];
  const [items, setItems] = useState(data);

  if (!categories) {
    return <div>Loading...</div>; 
  }

  return (
    <>
      {cartOpened && (
        <Cart
          cartItems={cartItems}
          onRemove={onRemove}
          onCartClose={() => setCartOpened(false)}
          onQuantityChange={handleQuantityChange}
          quantity={quantity}
          newQty={cartItems.newQty}
          newQuantity={quantity}
        />
      )}

      

      <div className='wrapper' id="top" style={{ flexDirection: 'column', paddingBlockStart: '7% !important', width: '100%' , backgroundColor:'gainsboro'}}>
        <div className='head' style={{ flexDirection: 'column', display: 'flex', marginTop: '6%', width: '30%'}}>
          <div className='head2' style={{ flexDirection: 'row', display: 'flex', width: '205px%', justifyContent: 'space-evenly', width: '50%', }}>
            <Link style={{ display: 'flex', flexDirection: 'row' }} to="/">Главная</Link> / <a style={{ display: 'flex' }}>{categories[selectedCategory - 1]?.attributes.title}</a>
          </div>
          <hr className='head-hr' style={{ display: 'none', border: 'none', height: '0.5px', color: '#333', backgroundColor: 'rgb(217 217 217)', width: '100%', marginTop: '6%' }} />
          <img  className='head-img'style={{ display: 'none',height: '31px', width: '40px', marginBottom: '5%', marginTop: '6%' }} src="./img/cat-slide-outer.png" onClick={showSlider} />
        </div>

        <div className='content' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' ,marginLeft:'12%'}}>
          {isMobile && open && (
            <Slider
              handleCategoryChange={handleCategoryChange}
              categories={categories}
              cartOpened={cartOpened}
              onClose={onClose}
              setOpen={setOpen}
            />
          )}

          <div className='category-list-container' style={{ overflowY: 'auto', maxHeight: '800px', width:'21%', padding:'26px', borderRadius:"25px",backgroundColor:'white' }}>
          {categories?.map((item) => (
            <CategoryList
              onCategoryChange={handleCategoryChange}
              key={item.id}
              title={item.attributes.title}
              id={item.id}
              selectedCategory={selectedCategory}
              categories={categories}
              itemCover={item.attributes.plainimage.data.attributes.url}
              item={item}
            />
          ))}
        </div>

 
        <ItemList
          categories={categories}
          selectedCategory={selectedCategory}
          showSlider={showSlider}
          items={items}
          setItems={setItems}
          onOpen={() => setCartOpened(true)}
          isAddedToCart={isAddedToCart}
          onAddToCart={onAddToCart}
          catname={catname}
          cartOpened={cartOpened}
          cartItems={cartItems}
          onCartClose={() => setCartOpened(false)}
          quantity={quantity}
          newQty={cartItems.newQty}
          newQuantity={quantity}
          setCartOpened={setCartOpened}
          setQuantity={setQuantity}
          setCartItems={setCartItems}
          count={count}
          increase={increase}
          setCount={setCount}
        />
        </div>
           <div className="mobile-footer">
          
          <div className="footer-link">
          <Link to='/catalog'>  
            <img className="footer-icon" style={{marginLeft:"25%",marginTop:'2%'}} alt="catalog" src="./img/catlg.svg" />
            <div className="footer-text">Каталог</div>
            </Link>
          </div>
          <div className="footer-link">
            <img style={{marginBottom:'1.5px' }} className="footer-icon" alt="wishlist" src="./img/love.svg" />
            <div className="footer-text">Список бажань</div>
          </div>
          <div className="footer-link">
            <img className="footer-icon" alt="cart" src="./img/cart.png" />
            <div className="footer-text"   onClick={() => setCartOpened(true)}>Кошик</div>
          </div>
        </div>
        </div>
        <div className="aside">
        <MyComponent/>   
           </div>
    </>
  );
};


  export default Catalog;
