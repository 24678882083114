import CategoryList from "./CategoryList";

function Slider({
  open,
  placement,
  showSlider,
  onClose,
  onChange,
  title,
  onCategoryChange,
  image,
  itemCover,
  item,
  handleCategoryChange,
  selectedCategory,
  categories,
  setOpen // Receive setOpen as a prop
}) {
  const handleClose = () => {
    onClose(); // Call onClose function to close the slider
    setOpen(false); // Call setOpen to update the open state to false
  };

  const handleClickOutside = (event) => {
    if (event.target) {
      handleClose();
    }
  };

  return (
    <div
      className="cartOverlay2"
      onClick={handleClickOutside} // Close the slider when clicking outside the content
    >
      <div className="cart d-flex flex-column v2">
        <div className="d-flex justify-between mb-25">
          <h2>Категории</h2>
          <img
            onClick={handleClose} // Call handleClose when the close button is clicked
            className="removeBtn"
            src="img/btn-remove.svg"
            alt="Close"
          />
        </div>
        <div className="cartItems mb-40">
          {categories?.map((item) => (
            <CategoryList
              onCategoryChange={handleCategoryChange}
              key={item.id}
              title={item.attributes.title}
              id={item.id}
              selectedCategory={selectedCategory}
              categories={categories}
              itemCover={item.attributes.plainimage.data.attributes.url}
              item={item}
            />
          ))}
        </div>

        <div className="d-flex justify-between align-center">
          <div>
            <span>Total</span>
            <span className="fw-bold ml-10"></span>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Slider;