
          import React, { useState, useEffect } from 'react';

          function CartItem({
            title,
            onRemove,
            itemCover,
            price,
            newPrice,
            quantity,
            onQuantityChange,
            taste,
            newQuantity, // Add newQuantity to the props
          }) {
            const [inputQuantity, setInputQuantity] = useState(newQuantity ? newQuantity : 1);
          
            // Update inputQuantity when newQuantity changes
            useEffect(() => {
              setInputQuantity(quantity ? quantity : 1);
            }, [quantity]);
          
            const handleQuantityChange = (newValue) => {
              const adjustedValue = Math.max(1, Math.min(Number(newValue), 100)); // Ensure value is within 1-100
              setInputQuantity(adjustedValue);
              onQuantityChange(taste, title, adjustedValue);
            };
          
            return (
              <div className="cartItem">
                <img className="" height="120px" src={`https://backend.primobar.com.ua${itemCover}`} alt=""></img>
                <div className="itemDescr">
                  <div className="cartit-taste">
                    <div className="cartit-taste-inner">{taste}</div>
                    <img
                      onClick={() => {
                        onRemove(title, taste);
                      }}
                      className="removeBtn "
                      src="img/btn-remove.png"
                      alt="Remove"
                    ></img>
                  </div>
                  <div className="cartit-price">
                    {quantity > 1 ? newPrice : price}
                    <div className="wrapper-for-quantity">
                      <img style={{width:'16px',height:'16px'}}
                        onClick={() => handleQuantityChange(inputQuantity + 1)}
                        className=""
                        src="/img/btn-add.png"
                        alt="plus"
                      ></img>
                      <input
                        className="quantity"
                        type="number"
                        value={inputQuantity}
                        onChange={(e) => handleQuantityChange(e.target.value)}
                        min="1"
                        max="100"
                      />
                      <img
                        onClick={() => {
                          if (inputQuantity > 1) {
                            handleQuantityChange(inputQuantity - 1);
                          }
                        }}
                        className=""
                        src="/img/btn-subtract.png"
                        alt="minus"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          
          export default CartItem;