import React, { useState,useEffect } from "react";
import { useValidation } from "../hooks/useValidation";
import Cart from '../components/Cart'
import { useHistory } from "react-router-dom";
import {  message } from 'antd';
import axios from 'axios';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import MyComponent from '../components/MyComponent'


const { Option } = Select;

function Order({cartOpened,
  setCartOpened,
  cartItems,
  onRemove,
  handleQuantityChange,quantity}) {

  const history = useHistory();
  const [promoCode, setPromoCode] = useState('');
  const [payment, setPayment] = useState({ selectedOption: "cash" });
  const [searchText, setSearchText] = useState('');
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const source = axios.CancelToken.source();
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Промокод на 20% активовано!',
    });
  };

  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Невірний промокод(',
    }); }
    
    useEffect(() => {
    const fetchCities = async () => {
      if (searchText.trim() === '') {
        setCities([]);
        return () => {
          debouncedFetchCities.cancel(); // Cancel the debounced fetch
          source.cancel('Component unmounted'); // Cancel the Axios request
        };      }
      
      console.log('Searching for city:', searchText);

      try {
        const response = await axios.post(
          'https://api.novaposhta.ua/v2.0/json/address/searchSettlements',
          {
            apiKey: '587fe0afa2bb178f596c4e8d5517ea2e',
            modelName: 'Address',
            calledMethod: 'searchSettlements',
            methodProperties: {
              CityName: searchText,
              Limit: '50',
              Page: '1'
            },
          },
          {
            cancelToken: source.token
          }
        );
        const { data, success } = response.data;
        if (success) {
          const cityData = data[0].Addresses;
          setCities(cityData);
          console.log('fetching cities:', cityData);
        } else {
          console.error('Error fetching cities:', response.data.errors);
        }
      } catch (error) {
        if (!axios.isCancel(error)) { // Check if the error is not due to cancellation
          console.error('Error fetching cities:', error);
        }
      }
    };

    const debouncedFetchCities = debounce(fetchCities, 500); // Debounce fetchCities function

    if (searchText.trim() !== '') {
      debouncedFetchCities(); // Trigger debounced fetch
    } else {
      setCities([]); // Clear cities if searchText is empty
    }

    return () => {
      debouncedFetchCities.cancel(); // Cancel the debounced fetch
      source.cancel('Component unmounted'); // Cancel the Axios request
    };
  }, [searchText]); // Only re-run effect if searchText changes
  
  const fetchWarehouses = async (deliveryCityRef, searchText) => {
    try {
      const response = await axios.post(
        'https://api.novaposhta.ua/v2.0/json/address/getWarehouses',
        {
          apiKey: '587fe0afa2bb178f596c4e8d5517ea2e',
          modelName: 'Address',
          calledMethod: 'getWarehouses',
          methodProperties: {
            FindByString: searchText,
            CityRef: deliveryCityRef
          }
        },
        {
          cancelToken: source.token
        }
      );
      const { data, success } = response.data;
      if (success) {
        setWarehouses(data);
        console.log('fetching warehouses:', data);
      } else {
        console.error('Error fetching warehouses:', response.data.errors);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error('Error fetching warehouses:', error);
      }
    }
    
  };
  useEffect(() => {
    const fetchWarehouses = async (deliveryCityRef) => {
      try {
        const response = await axios.post(
          'https://api.novaposhta.ua/v2.0/json/address/getWarehouses',
          {
            apiKey: '587fe0afa2bb178f596c4e8d5517ea2e',
            modelName: 'Address',
            calledMethod: 'getWarehouses',
            methodProperties: {
              FindByString: '', // Pass empty string or the search text, depending on your API
              CityRef: deliveryCityRef
            }
          },
          {
            cancelToken: source.token
          }
        );
        const { data, success } = response.data;
        if (success) {
          setWarehouses(data);
          console.log('fetching warehouses:', data);
        } else {
          console.error('Error fetching warehouses:', response.data.errors);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error('Error fetching warehouses:', error);
        }
      }
    };
  
    if (selectedCity !== '') {
      fetchWarehouses(selectedCity);
    }
  
    return () => {
      source.cancel('Component unmounted'); // Cancel the Axios request
    };
  }, [selectedCity]);
  const handleCitySelect = (cityRef, deliveryCityRef) => {
    setSelectedCity(cityRef);
    fetchWarehouses(deliveryCityRef); // Pass the DeliveryCityRef to fetchWarehouses
  };
  

  const handleWarehouseSelect = (warehouseRef) => {
    setSelectedWarehouse(warehouseRef);
  };
  const handleApplyPromoCode = (e,promoCode) => {
    if (promoCode.toLowerCase() === 'primo20') {
      setPromoCode('primo20')
      {success()}
      console.log(promoCode,'promo output')

    } else { 
      {error()}

  }}
  

  const handleSubmit = async (e, cartItems, promoCode) => {
    e.preventDefault();
  
    cartItems?.forEach(item => {
      if (!item.hasOwnProperty('quantity')) {
        item.quantity = 1; // Or your default quantity
      }
      if (!item.hasOwnProperty('newPrice')) {
        item.newPrice = item.attributes.price; // Or your default newPrice
      }
  
      // Simplify img structure (optional)
      if (item.img?.data) {
        const [imgId, imgAttributes] = item.img.data;
        item.img = { id: imgId, attributes: imgAttributes };
      } else console.error("cartItems is undefined", cartItems);
    
    });
  

    const cityName = cities.find(city => city.Ref === selectedCity)?.MainDescription;
    const warehouseDescription = warehouses.find(warehouse => warehouse.Ref === selectedWarehouse)?.Description;
    const credentials = {
      data: {
        name: name.value,
        surname: surname.value,
        phone: phone.value,
        city: cityName, // Use city name instead of reference
        post_number: warehouseDescription, // Use warehouse description instead of reference
        payment: payment.selectedOption,
        promoCode: promoCode,
        cartItems: cartItems ? JSON.stringify(cartItems) : [JSON.stringify(cartItems)], // Directly include the cartItems object
      },
    };
    try {
      const res = await fetch("https://backend.primobar.com.ua/api/orders", {
        method: "POST",
        body: JSON.stringify(credentials), // Send the entire credentials object
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // Handle success, e.g., redirect or show a success message
    //  console.error("Success:", res);
    } catch (error) {
      console.error("Error:", error);
    }
      history.push("/complete");

  };
    
    const useInput = (initialValue, validations) => {
      const [value, setValue] = useState("");
      const [isDirty, setDirty] = useState(false);

      const valid = useValidation(value, validations);

      const onChange = (e) => {
        setValue(e.target.value);
      };

      const onBlur = () => {
        setDirty(true);
      };

      const onInput = () => {
        setDirty(false);
      };

      return {
        value,
        onInput,
        onChange,
        onBlur,
        isDirty,
        ...valid,
      };
    };

  const name = useInput("", {
    isEmpty: true,
    minLength: 7,
    nameError: false,
  });

  const surname = useInput("", {
    isEmpty: true,
    minLength: 7,
    surnameError: false,
  });

  const phone = useInput("", {
    isEmpty: true,
    minLength: 7,
    phoneError: false,
  });

  const city = useInput("", {
    isEmpty: true,
    minLength: 3,
    cityError: false,
  });

  const post_number = useInput("", {
    isEmpty: true,
    minLength: 7,
    postNubmerError: false,
  });
  console.log(cartItems,'cartitens before rtrn (order)')

  return (
    <>
        {contextHolder}
      {cartOpened && (
          <Cart
          
            cartItems={cartItems}
            onRemove={onRemove}
            onCartClose={() => setCartOpened(false)}
            onClick={() => console.log(cartItems)}
          
            />          
        )}

    <div className="wrapper">
      <form className="data-input d-flex " >
        <div className="form_inner justify-around d-flex flex-column ">
          <h1 style={{display:'none'}} >Введите данные для отправки:</h1>

          <div className="d-flex flex-row justify-between">
            <div className="d-flex  flex-column">
              <div className="mb-10 d-flex">
                <label htmlFor="uname"> Имя </label>
              </div>
              <input
                placeholder="Василий"
                value={name.value}
                onChange={(e) => name.onChange(e)}
                // onChange={(e) =>console.log(cartItems,'crtitms')}

                onBlur={(e) => name.onBlur(e)}
                onInput={(e) => name.onInput(e)}
                name="username"
                className="name-inpt"
                type="string"
                id="name"
                maxLength="12"
                required
              />

              {name.isDirty && name.nameError && (
                <div
                  style={{
                    color: "red",
                    marginLeft: "10px",
                    font: "small-caption",
                  }}
                >
                  {" "}
                  Некорректное имя{" "}
                </div>
              )}
            </div>

            <div className="d-flex flex-column">
              <div className="mb-10 d-flex">
                <label htmlFor="surname"> Фамилия </label>
              </div>
              <input
                placeholder="Мороз"
                value={surname.value}
                onChange={(e) => surname.onChange(e)}
                onBlur={(e) => surname.onBlur(e)}
                onInput={(e) => surname.onInput(e)}
                name="surname"
                className="pass-inpt"
                type="string"
                id="surname"
                maxLength="13"
                required
              />
              {surname.isDirty && surname.surnameError && (
                <div
                  style={{
                    color: "red",
                    marginLeft: "10px",
                    font: "small-caption",
                  }}
                >
                  {" "}
                  Некорректная фамилия{" "}
                </div>
              )}
            </div>
          </div>

          <label htmlFor="phone"> Номер телефона </label>
          {phone.isDirty && phone.phoneError && (
            <div style={{ color: "red", font: "small-caption" }}>
              {" "}
              Некорректный номер{" "}
            </div>
          )}
          <input
            placeholder="380"
            value={phone.value}
            onChange={(e) => phone.onChange(e)}
            onBlur={(e) => phone.onBlur(e)}
            onInput={(e) => phone.onInput(e)}
            name="phone"
            className="name-inpt"
            id="phone"
            type="number"
            required
          />
{/* 
          <label htmlFor="city"> Город </label>
          {city.isDirty && city.cityError && (
            <div style={{ color: "red", font: "small-caption" }}>
              Некорректный город
            </div>
          )}
          <input
            placeholder="Киев"
            value={city.value}
            onChange={(e) => city.onChange(e)}
            onBlur={(e) => city.onBlur(e)}
            onInput={(e) => city.onInput(e)}
            name="username"
            className="name-inpt"
            id="city"
            maxLength="17"
            required
          />

          <label htmlFor="postnumber"> Номер почтового отделения </label>

          {post_number.isDirty && post_number.postNubmerError && (
            <div style={{ color: "red", font: "small-caption" }}>
              Некорректный номер
            </div>
          )}
          <input
            placeholder="174"
            value={post_number.value}
            onChange={(e) => post_number.onChange(e)}
            onBlur={(e) => post_number.onBlur(e)}
            onInput={(e) => post_number.onInput(e)}
            name="username"
            className="name-inpt"
            id="postnumber"
            type="number"
            min="1"
            max="9999"
            required="true"
          /> */}
<>
    
<label htmlFor="city"> Город </label>
<Select
        showSearch
        value={selectedCity}
        placeholder="Enter city name..."
        style={{ width: 200 }}
        onChange={(cityRef, { props: { deliveryCityRef } }) => handleCitySelect(cityRef, deliveryCityRef)}
        filterOption={false}
        onSearch={setSearchText} // Use setSearchText as the debounced handler
      >
        {cities.map((city) => (
          <Option key={city.Ref} value={city.Ref} deliveryCityRef={city.DeliveryCity}>
            {city.MainDescription}
          </Option>
        ))}
      </Select>      <label htmlFor="postnumber"> Номер почтового отделения </label>

      <Select
  showSearch
  value={selectedWarehouse}
  placeholder="Select warehouse..."
  style={{ width: 200, marginTop: 16 }}
  onChange={handleWarehouseSelect}
  filterOption={(input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    (option.props.description &&
      option.props.description.toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }
>
  {warehouses.map((warehouse) => (
    <Option
      key={warehouse.Ref}
      value={warehouse.Ref}
      description={warehouse.Description}
    >
      {`${warehouse.Description}`}
    </Option>
  ))}
</Select>
    </>
          <div className="d-flex flex-column">
            <div className="mb-10 d-flex">
              <input
                type="radio"
                id="scales"
                name="payon"
                onChange={() => setPayment({ selectedOption: "cash" })}
                checked={payment.selectedOption === "cash"}
              />
              <label htmlFor="scales">Я хочу оплатить на почте</label>
            </div>

            <div>
              <input
                type="radio"
                id="scales2"
                name="payon"
                onChange={() => setPayment({ selectedOption: "card" })}
                checked={payment.selectedOption === "card"}
              />
              <label htmlFor="scales2">Я хочу оплатить на карту</label>
            </div>
          </div>

          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'baseline'}}>
              <input type="text" value={promoCode} onChange={(e) => setPromoCode(e.target.value)}   
              placeholder="Enter promo code"
              style={{width:'44%'}}
              />

              <button
                type="button" // Change the type to "button" to prevent form submission
                className="promo-butt"
                style={{ display: 'flex',alignItems:'center' }}
                onClick={(e) => handleApplyPromoCode(e, promoCode)
                }
              >
              Застосувати
              </button>

          </div> 

          <button
            disabled={
              !phone.inputValid ||
              // !city.inputValid ||
              // !post_number.inputValid ||
              !name.inputValid
            }
            type="submit"
            className="submit-login"
            onClick={(e) => handleSubmit(e,cartItems,promoCode,selectedCity,selectedWarehouse)  }
            >
            Підтвердити{" "}
          </button>
        </div>
        <div className="form_elfs">
          {" "}
          <img className="hand-elfs" style={{display:"none"}}  src="/img/hand-elfs.png" alt="elfbars" />
        </div>
      </form>
    </div>
    <div className="mobile-footer">
          
          <div className="footer-link">
          <Link to='/catalog'>  
            <img className="footer-icon" style={{marginLeft:"25%",marginTop:'2%'}} alt="catalog" src="./img/catlg.svg" />
            <div className="footer-text">Каталог</div>
            </Link>
          </div>
          <div className="footer-link">
            <img className="footer-icon" alt="wishlist" src="./img/love.svg" />
            <div className="footer-text">Список бажань</div>
          </div>
          <div className="footer-link">
            <img className="footer-icon" alt="cart" src="./img/cart.png" />
            <div className="footer-text"   onClick={() => setCartOpened(true)}>Кошик</div>
          </div>
        </div>
        <div className="aside">
        <MyComponent/>   
           </div>
  </>);
}
export default Order;
