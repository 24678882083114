
import React from 'react';
import { Col, Row, Avatar } from 'antd';

const CategoryList = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  handleCategoryChange, // Corrected typo (Chang -> Change)
  id,
  title,
  onCategoryChange,
  image,
  itemCover,
  item,
}) => {
  return (
    <>
      {/* {categories.map((item) => ( */}
        <div
          className="popa" // Removed extra space
          style={{ display: 'flex', marginBottom: '5%',alignItems:'center'}}

        >
  
              <Avatar
                onClick={() => onCategoryChange(item.id)} // Use item.id for click handler
                style={{ justifyContent: 'center', verticalAlign: 'center' }}
                size={70}
                src={<img src={`https://backend.primobar.com.ua${itemCover}`} alt="avatar" />}
              />
            {title}
        </div>
       
    </>
  );
};

export default CategoryList;