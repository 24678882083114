
      
import React, { useState } from 'react';
import { Col, Row,  } from 'antd';
import Card1 from './Card1';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';




const ItemList = ({ selectedCategory,data,setItems,categories,onAddToCart,onOpen,isAddedToCart2,cartOpened,isAddedToCart, onQuantityChange,      items,
  setCartOpened,
  cartItems,
  setQuantity,
  setCartItems,
  quantity,showSlider,
  count,
  increase,
  setCount,}) => {
  

    const fetchItems = async (selectedCategory) => {
    
      const selectedCategoryName = categories.find((category) => category.id === selectedCategory);
      const categoryName = selectedCategoryName.attributes.catname;    
      // console.log() 
      // Implement your fetch logic here, using the category parameter
      const response = await fetch(`https://backend.primobar.com.ua/api/categories/${selectedCategory}?populate[${categoryName}][populate][img]=url`);
      const {data} = await response.json();
      console.log(data,'data in fetchitems')
      console.log(categories,'categories in fetchitems')
      console.log(categoryName,'categoryNAME in fetchitems')   
      
      const itemData =  Object.values(data.attributes).filter((item) => {
        return typeof item === "object" && item.hasOwnProperty("data")
      }).map(obj => obj.data).flat();
    
      setItems(itemData); // Update the items state with the fetched data
      console.log(itemData,'itemdata')
    };

    useEffect((categoryName) => {
      // Fetch items for the selected category
      console.log('selectedCategory when fetch Items', selectedCategory)
      console.log(categoryName,'categoryNAME when fetch Items')   

      fetchItems(selectedCategory || 1)
      console.log(items,'items in itemlist!!!!!')

    }, [selectedCategory]);

  
    return (
     <>
             
               <div className=" items">

        { items.map((item) => (  
         
          <Card1 key={item.id} itemCover={item.attributes.img?.data.attributes?.url} title={item.attributes.title}    
          onAddToCart={() => onAddToCart(item,increase)}
        taste={item.attributes.taste}
        catname={item.attributes.title}
          onOpen={() => setCartOpened(true)}
          isAddedToCart={isAddedToCart}           
                 cartItems={cartItems}
                 increase={increase}
                 price={item.attributes.price}
></Card1>
      ))}

      </div>


  </>)
}
  export default ItemList;


  


  