import { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Order from "./pages/Order";
import Footer from "./components/Footer";
import ItemList from "./components/ItemList";
import Catalog from "./pages/Catalog";
import Footer2 from "./components/Footer2";
import { SmileOutlined } from '@ant-design/icons';
import {  Result } from 'antd';
import Homes from "./components/Homes";



function App() {
  const [searchValue, setSearchValue] = useState("");
  const [cartOpened, setCartOpened] = useState(false);
  const [categories, setCategories] = useState();
  const [items, setItems] = useState([]);
  const [cartItems, setCartItems] = useState([])
  const [quantity, setQuantity] = useState(0)
  const [count, setCount] = useState(0);


  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = useState(false);
  const onClose2 = () => {
    setOpen2(false);
  };
  const onAddToCart = (obj,increase) => {
    setCartItems((prev) => [...prev,obj])
    setCount(count + 1);
        console.log("onAddToCard,cartItems:", cartItems,(cartItems.length));
  };

  const onRemoveItem = (title,taste) => {
    setCartItems((prev) => prev.filter((item) => item.attributes.title+item.attributes.taste !== title+taste));
    setCount(count - 1);

    console.log("onRemove, cartItems:", cartItems);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('https://backend.primobar.com.ua/api/categories?populate[plainimage][fields]=url');
      const result = await response.json();
   // 'http://localhost:1337/api/categories?populate=*'

      // const response_pi7 = await fetch('http://localhost:1337/api/categories?populate[item_pi_7000s][populate][0]=img');
      // const result_pi = await response_pi7.json();


      const response_general = await fetch('https://backend.primobar.com.ua/api/generalitems?populate[img][fields]=url');
      const result2 = await response_general.json();
      
      setItems(result2.data)
      setCategories(result.data)

      console.log(result, 'categories`s items ')
      console.log(result2, 'general items ')
      console.log(cartItems, 'cartItems ON LOAD')

    };
    fetchData();

  }, []);

  function handleQuantityChange  (taste,title, newQuantity)  {
    const newCart = cartItems.map(item => {

       if (item.attributes.taste+item.attributes.title === taste+title) {
        return { ...item, quantity: newQuantity,newPrice:newQuantity*item.attributes.price };
      }  
      return {...item}

    });
    setCartItems(newCart);
  }

  const isAddedToCart = (taste) => cartItems.some((item) => item.attributes.taste === taste);


  const increase = (count) => {
    setCount(count + 1);
  };
  const onChangeSearchInput = (event) => {
    setSearchValue(event.target.value);
  };
  console.log(cartItems,'cartitens before rtrn (appjs)')
  return (
    <div name="main-page" className="clear">

<Header onCartOpen={() => setCartOpened(true)} cartItems={cartItems} items={items} count={count}  
    setOpen2={setOpen2}   
    open2={open2}
    showDrawer={() => setOpen2(true)}
//             placement={placement}
//             showDrawer={showDrawer}
            onClose2={onClose2}/>

          <Route path="/" exact>
            <Home
                 isAddedToCart={isAddedToCart}
                 cartOpened={cartOpened}
                 onAddToCart={onAddToCart}
                 onRemove={onRemoveItem}
                 items={items}
                 searchValue={searchValue}
                 onChangeSearchInput={onChangeSearchInput}
                 setSearchValue={setSearchValue}
                 setCartOpened={setCartOpened}
                 cartItems={cartItems}
                 setQuantity={setQuantity}
                 setCartItems={setCartItems}
                 count={count}
                 increase={increase}
                 handleQuantityChange={handleQuantityChange}
                 setCount={setCount}
                 quantity={quantity>1?quantity.newQuantity:1} />
          </Route>

          <Route  path="/catalog">
              <Catalog    
               onClose={onClose}
               showDrawer={() => setOpen(true)}

               categories={categories}
               open={open}
               setOpen={setOpen}
               showSlider={() => setOpen(true)}
              cartOpened={cartOpened}
              //  items={items}
              searchValue={searchValue}
              onChangeSearchInput={onChangeSearchInput}
              setCartOpened={setCartOpened}
              cartItems={cartItems}
              setQuantity={setQuantity}
              setCartItems={setCartItems}
              quantity={quantity}
              count={count}
              increase={increase}
              setCount={setCount}
              isAddedToCart={isAddedToCart}
              onAddToCart={onAddToCart}
              onRemove={onRemoveItem}
              handleQuantityChange={handleQuantityChange}
              />
          </Route> 

           <Route path="/itemlist">
             <ItemList  />  
           </Route> 


          <Route path="/order">
            <Order 
              handleQuantityChange={handleQuantityChange}
              cartItems={cartItems}
              cartOpened={cartOpened}
              setCartOpened={setCartOpened}
              setQuantity={setQuantity}
              quantity={quantity}
              count={count}
              increase={increase}
              setCount={setCount}
              isAddedToCart={isAddedToCart}
              onAddToCart={onAddToCart}
              onRemove={onRemoveItem}
/>
          </Route>


          <Route path="/complete">
            <div className="wrapper complete">
            <Result
    icon={<SmileOutlined />}
    title="Ваше замовлення створено, очікуйте смс повідомлення з накладною, бережіть себе!"
  />            </div>
          </Route>


          

      {/*  */}

      {/*  */}

      {/* <Footer /> */}
    </div>
  );
}

export default App;
