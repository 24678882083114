function MyComponent() {
  return (
      <>
          <div className="div">
              <div className="div-2">
                  <div className="div-3">
                      <div className="column">
                         
                      </div>
                      <div className="column-2">
                      
                          <div className="div-6">
                              <div className="div-7">Контакти</div>
                              <div className="div-8">+38 (067) 735 74 47</div>
                              <div className="div-9">Графік роботи:</div>
                              <div className="div-10">Пн-Нд 09:00-20:00</div>
                          </div>
                      </div>
                      <div className="column-3">
                          <div className="div-11">
                              <div className="div-12">Для клієнтів</div>
                              <div className="div-13">Питання-Відповідь</div>
                              <div className="div-14">Доставка та оплата</div>
                              <div className="div-15">Обмін та повернення</div>
                              <div className="div-16">Блог</div>
                          </div>
                      </div>
                      <div className="column-4">
                      <div className="div-4">                               
                      <div className="div-5">Ми в соц. мережі:          
                      <div className="link">
                    <div className="text-wrapper-5">Telegram</div>
                     </div>
                              </div>

                             
                          </div>
                          <div className="div-17"> 
                              <div className="div-18">Підписуйтесь на наш телеграм канал </div>
                              {/* <div className="link">
          <div className="text-wrapper-5">Telegram</div>
        </div> */}
        </div>
                      </div>  
                  </div>
              </div>
              <div className="div-20">
                  © 2024 <span style={{ color: 'rgba(255,255,255,1)' }}>ELFBAR</span>. Всі права захищені
              </div>
          </div>
          <style jsx>{`
//  .link {
//   align-items: flex-start;
//   background-color: #27ace2;
//   border-radius: 10px;
//   display: inline-flex;
//   left: 0;
//   padding: 10px 52.98px 9px 50px;
//   position: absolute;
//   top: 97px;
// width:75%
// }

.div {
align-self: stretch;
background-color: #212121;
display: flex;
flex-direction: column;
}
.div-2 {
justify-content: center;
align-content: flex-start;
align-self: center;
flex-wrap: wrap;
width: 100%;
max-width: 1349px;
padding: 27px 15px 22px;
}
@media (max-width: 991px) {
.div-2 {
  max-width: 100%;
}
}
.div-3 {
gap: 20px;
display: flex;  
justify-content: end;
  margin-right: 5%;
}
@media (max-width: 991px) {
.div-3 {
  flex-direction: column;
  align-items: stretch;
  gap: 0px;
}
}
.column {
display: none;
flex-direction: column;
line-height: normal;
width: 25%;
margin-left: 0px;
}
@media (max-width: 991px) {
.column {
  width: 98%;
}
}
.div-4 {
align-self: stretch;
display: flex;
flex-direction: column;
font-size: 16px;
color: #fff;
font-weight: 400;
line-height: 22px;
}
@media (max-width: 991px) {
.div-4 {
  margin-top: 40px;
      flex-direction: column;
}
}
.img {
aspect-ratio: 4.76;
object-fit: auto;
object-position: center;
width: 307px;
align-self: center;
}
.div-5 {
font-family: Arial, sans-serif;
  text-transform: uppercase;
   font: 600 16px Arial, sans-serif;
       display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
}
.img-2 {
aspect-ratio: 1;
object-fit: auto;
object-position: center;
width: 35px;
margin-top: 38px;
}
.column-2 {
display: flex;
flex-direction: column;
line-height: normal;
width: 25%;
margin-left: 20px;
}
@media (max-width: 991px) {
.column-2 {
  width: 98%;
}
}
.div-6 {
align-self: stretch;
display: flex;
flex-direction: column;
font-size: 16px;
color: rgba(255, 255, 255, 0.8);
font-weight: 700;
line-height: 140%;
}
@media (max-width: 991px) {
.div-6 {
  margin-top: 40px;
}
}
.div-7 {
color: #fff;
font-family: Arial, sans-serif;
text-transform: uppercase;
}
.div-8 {
color: #c6c6c6;
font-family: Arial, sans-serif;
font-weight: 400;
line-height: 30px;
margin-top: 20px;
}
.div-9 {
margin-top: 36px;
font: 17px/20px Arial, sans-serif;
}
.div-10 {
margin-top: 12px;
font: 400 14px Arial, sans-serif;
}
.column-3 {
display: flex;
flex-direction: column;
line-height: normal;
width: 25%;
margin-left: 20px;
}
@media (max-width: 991px) {
.column-3 {
  width: 98%;
}
}
.div-11 {
align-self: stretch;
display: flex;
flex-direction: column;
font-size: 14px;
color: rgba(255, 255, 255, 0.6);
font-weight: 400;
line-height: 140%;
}
@media (max-width: 991px) {
.div-11 {
  margin-top: 40px;
}
}
.div-12 {
color: #fff;
text-transform: uppercase;
font: 700 16px Arial, sans-serif;
}
.div-13 {
font-family: Arial, sans-serif;
margin-top: 20px;
}
.div-14 {
font-family: Arial, sans-serif;
margin-top: 14px;
}
.div-15 {
font-family: Arial, sans-serif;
margin-top: 14px;
}
.div-16 {
font-family: Arial, sans-serif;
margin-top: 14px;
}
.column-4 {
display: flex;
flex-direction: column;
line-height: normal;
width: 25%;
margin-left: 20px;
}
@media (max-width: 991px) {
.column-4 {
  width: 98%;
}
}
.div-17 {
align-self: stretch;
display: flex;
flex-direction: column;
font-size: 14px;
font-weight: 400;
line-height: 140%;
}
@media (max-width: 991px) {
.div-17 {
  margin-top: 40px;
}
}
.div-18 {
color: rgba(255, 255, 255, 0.8);
font-family: Arial, sans-serif;
margin-top:14px
}.link {
align-items: flex-start;
background-color: #27ace2;
border-radius: 10px;
display: inline-flex;
left: 0;
padding: 10px 52.98px 9px 50px;
position: unset;
top: 191px;
width: 60%;
}.text-wrapper-5 {
color: #ffffff;
font-family: "Arial-Regular", Helvetica;
font-size: 14px;
font-weight: 400;
letter-spacing: 0;
line-height: 19.6px;
margin-top: -1px;
position: relative;
white-space: nowrap;
width: fit-content;
}

.div-19 {
font-family: Arial, sans-serif;
border-radius: 10px;
background-color: #27ace2;
color: #fff;
white-space: nowrap;
justify-content: center;
padding: 10px 50px;
}
@media (max-width: 991px) {
.div-19 {
  white-space: initial;
  padding: 0 20px;
}
}
.div-20 {
align-items: center;
border-color: rgba(255, 255, 255, 0.15);
border-style: solid;
border-top-width: 1px;
width: 100%;
color: rgba(255, 255, 255, 0.9);
text-align: center;
padding: 1px 60px 21px;
font: 400 14px/16.8px Arial, -apple-system, Roboto, Helvetica,
  sans-serif;
  padding-block-start:1%
}
@media (max-width: 991px) {
.div-20 {
  margin-top: -20%;
  max-width: 100%;
  padding: 0 20px;
}
}
`}</style>
      </>
      
  );
  
}


export default MyComponent;