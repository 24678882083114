import { Link } from "react-router-dom";
import { ClockCircleOutlined } from '@ant-design/icons';
import { MinusOutlined, PlusOutlined, QuestionOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Space, Switch } from 'antd';
import { useState } from 'react';
import Homes from "./Homes";

const ButtonGroup = Button.Group;
function Header({ onCartOpen ,CartItems,items,count,open2,onClose2,showDrawer,setOpen2}) {
  return (
    <div className="d-flex ">

    <header className="wrapper-head d-flex justify-around align-center pb-5" style={{height:'65px'}}> {open2 && (
        <Homes
        open2={open2}
        setOpen2={setOpen2}
        showDrawer={() => setOpen2()}
        onClose2={() => onClose2(setOpen2(false))}
        />
      )}
    <img src="../img/butter-npg.png" onClick={showDrawer} style={{display:'none',marginBottom:'1%', height:'18px'}} className="butter"></img>      
     <div className="left-wrap" style={{marginBottom:'1%'}}>
              <div className="d-flex align-center" style={{display:'flex',justifyContent:'space-around'}}>
              <Link to="/"  style={{display:'flex'}}>
            <img  className="head-logo" src="/logo-trim-primo.png" style={{ width:"100%",height:"25px",marginLeft:'7%'}} alt="logo" />
                </Link>
              </div>

              <div className="d-flex align-center" style={{marginLeft:'70px'}}>
              <Link to="/catalog">
                <img className="cat-logo"   src="../img/catalog.svg" />
                </Link>
                </div>
          </div>

        <ul className="d-flex">
        { /*   <li className="mr-30"> */}
            {/* <Link to="/"> */}
        { /*   <a style={{ color: "black", fontSize: 19 }} href="#main-wr">Главная</a> */}
            {/* </Link> */}
            { /*   </li> */}
           { /* <li className="mr-30"> */}
           { /*  <a style={{ color: "black", fontSize: 19 }} href="#promo-info">Про нас</a> */}
        { /*  </li> */}
          { /* <li className="mr-30"> */}
          { /* <a style={{ color: "black", fontSize: 19 }} href="#promo-info">Информация</a> */}
           { /*</li> */}
        </ul>
        <div className="d-flex align-center">
          <span className="mr-10 header-phone"></span>
      <Space size="large">

       <Badge count={count}   >


    
    
          <div
            className="wrapper-for-cart d-flex cu-p"
            onClick={() => onCartOpen()}
          >
               <img width="62" height="62" src="/img/cart.png" alt="cart" style={{height:'32px',width:'36px'}} />     

          </div>
          </Badge>
      </Space>
        </div>
        
      </header>
      </div>

  );
}
export default Header;
