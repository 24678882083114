import Card1 from "../components/Card1";
import { Link, } from "react-router-dom";
import { useState,useEffect } from 'react';
import Cart from "../components/Cart";
import { Avatar, Badge, Button, Space, Switch,Carousel  } from 'antd';
import { CapsuleTabs, Footer } from 'antd-mobile'
import MyComponent from '../components/MyComponent'

const ButtonGroup = Button.Group;
//192.168.0.126
function Home({
  searchValue,
  onChangeSearchInput,
  setSearchValue,
  setCartOpened,
  items,
  cartOpened,
  cartItems,
  setCartItems,
  quantity,
  count,
  setCount,increase,isAddedToCart,onAddToCart,handleQuantityChange
  ,onRemove,
}) 
{    
  const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79', 
    borderRadius:'20px'

  }; 

  return (
    
    <>
        {cartOpened && (
          <Cart
          cartItems={cartItems}
          onCartClose={() => setCartOpened(false) }
          onRemove={onRemove}
          onQuantityChange={handleQuantityChange}
          quantity={quantity}

            
            />          
        ) } 

      <div name="promo" className="promo" style={{display:'flex '}}>
        <div className="wrapper " >
           <div className="hero-text">            
               <h1  style={{ display:"flex",color:'grey',margin:0}} >Офiцiйний</h1>
               <h1 className="head-text" > Онлайн-магазин ELF BAR <br/>В Українi</h1>
               <a   style={{ display:"flex",flexDirection:
              'column',height:'400',}}>
               <a href="#content-catalog">
               Краща ціна та реальна наявність товару.
              <button className="hero-btn" style={{display:'block',marginTop:'10%'}}>Переглянути наявність</button>
            </a>
               </a>
               </div>
               <Carousel style={{}} autoplay>
    <div>
      <h3 style={contentStyle}><img
                className="box-shadow"
                 width="100%"
               height="405px"
                  src="/img/elfprimo-default.png"
                 alt=""
                /></h3>
    </div>
    <div>
      <h3 style={contentStyle}><img
                 className="box-shadow"
                 width="100%"
               height="405px"
                  src="/img/elfprimo-default.png"
                 alt=""
                /></h3>
    </div>
 
              </Carousel>
              </div> 
           <div className="wrapper-main ">
              <div className="search-block">
            <a name="content-catalog">
              <h3 name="promo" className="content-availavble">
                {searchValue
                  ? `Поиск по запросу: "${searchValue}"`
                  : <Link to='/catalog'> {`<`} Каталог</Link>}
              </h3>
            </a>
            <div className="search d-flex">
             
              <input className='srchblock-inpt'
                onChange={(event) => onChangeSearchInput(event)}
                value={searchValue}
                placeholder="Поиск..."
                style={{width:"50%",fontSize:"16px"}}
              ></input>
               <img style={{marginLeft:"-13%",marginRight:"5%" }} src="/img/search.svg" alt="Search"></img>
              {searchValue && (
                
                <img
                  onClick={() => setSearchValue("")}
                  className="removeBtn cu-p"
                  src="img/btn-remove.png"
                  alt="Clear"
                ></img>
              )}
            </div>
          </div>  

             <div className="searchpanel"style={{display:'none',justifyContent:'space-between',backgroundColor:'#f5f5f5',borderRadius:'20px'}}>
             <>
        <CapsuleTabs>
          <CapsuleTabs.Tab title='Новинки' key='isNewArrival'
              onClick={() => {
                console.log('New Arrivals filter clicked');
              
              }}
                    >  
          <div name="promo" className="content">
           <div className=" items">
            
           {items
    .filter(
      (it) =>
        it.attributes.isNewArrival &&
        (it.attributes.taste.toLowerCase().includes(searchValue.toLowerCase()) ||
          it.attributes.catname.toLowerCase().includes(searchValue.toLowerCase()))
    ).map((item) => (
          <Card1
        key={item.attributes.taste}
          title={item.attributes.title}
          taste={item.attributes.taste}
          isAddedToCart={isAddedToCart}
          price={item.attributes.price}
          catname={item.attributes.catname}
          itemCover={item.attributes.img.data.attributes?.url}
          cartItems={cartItems}
          onAddToCart={() => onAddToCart(item,count)}
          onOpen={() => setCartOpened(true)}
          increase={increase}
          // activeCapsuleTabsState={activeCapsuleTabsState}
          />))}
       
        </div>    
      </div>

  
          </CapsuleTabs.Tab>
          <CapsuleTabs.Tab title='Популярні' key='isPopular'
                   onClick={() => {
                    console.log('isPopular filter clicked');
               
                  }}
                     >
             <div name="promo" className="content">

      <div className=" items">
            
      {items
    .filter(
      (it) =>
        it.attributes.isPopular &&
        (it.attributes.taste.toLowerCase().includes(searchValue.toLowerCase()) ||
          it.attributes.catname.toLowerCase().includes(searchValue.toLowerCase()))
    ).map((item) => (
          <Card1
        key={item.attributes.taste}
          title={item.attributes.title}
          taste={item.attributes.taste}
          isAddedToCart={isAddedToCart}
          price={item.attributes.price}
          catname={item.attributes.catname}
          itemCover={item.attributes.img.data.attributes?.url}
          cartItems={cartItems}
          onAddToCart={() => onAddToCart(item,count)}
          onOpen={() => setCartOpened(true)}
          increase={increase}
          // activeCapsuleTabsState={activeCapsuleTabsState}
          />))}
       
    </div>    </div>

          </CapsuleTabs.Tab>

          <CapsuleTabs.Tab title='Знижка' key='isOnSale'
          onClick={() => {
            console.log('isOnSale filter clicked');

          }}                  >
             <div name="promo" className="content">
          <div className=" items">
            
          {items
    .filter(
      (it) =>
        it.attributes.isOnSale &&
        (it.attributes.taste.toLowerCase().includes(searchValue.toLowerCase()) ||
          it.attributes.catname.toLowerCase().includes(searchValue.toLowerCase()))
    ).map((item) => (
          <Card1
        key={item.attributes.taste}
          title={item.attributes.title}
          taste={item.attributes.taste}
          isAddedToCart={isAddedToCart}
          price={item.attributes.price}
          catname={item.attributes.catname}
          itemCover={item.attributes.img.data.attributes?.url}
          cartItems={cartItems}
          onAddToCart={() => onAddToCart(item,count)}
          onOpen={() => setCartOpened(true)}
          increase={increase}
          />))}
       
    </div>

    </div>
          </CapsuleTabs.Tab >
        </CapsuleTabs>
        
        </>
        
        </div>

  
          <div name="promo" className="content">

 
          <div className=" items">
            
                  {items.filter((item) =>  item.attributes.taste.toLowerCase().includes(searchValue.toLowerCase())).map((item) => (
                <Card1
              key={item.attributes.taste}
                title={item.attributes.title}
                taste={item.attributes.taste}
                isAddedToCart={isAddedToCart}
                price={item.attributes.price}
                catname={item.attributes.catname}
                itemCover={item.attributes.img.data.attributes?.url}
                cartItems={cartItems}
                onAddToCart={() => onAddToCart(item,count)}
                onOpen={() => setCartOpened(true)}
                increase={increase}
                // activeCapsuleTabsState={activeCapsuleTabsState}
                />))}
             
          </div>

          </div>

          </div>
          <div className="info mt-50" style={{height:'495px'}} >
            <a name="promo-info">
              <h1 style={{padding:'2%',color:'#d1d1d1'}}> Чому ми ?: </h1>
            </a>
            <ul className="searschbar">
              <li style={{padding:'3%',color:"#afafaf"}}>
                <img
                  width="72"
                  height="72"
                  src="/img/info/quality.png"
                  alt="quality"
                />
                <span style={{ marginLeft:'4%'}}>Тільки оригінальна продукція</span>
              </li>
              <li style={{padding:'3%',color:"#afafaf"}}>
                <img width="72" height="72" src="/img/info/speed.png" alt="" />
                <span style={{ marginLeft:'4%'}}>Швидка обробка та відправлення ваших замовлень</span>
              </li>
              <li style={{padding:'3%',color:"#afafaf"}}>
                <img
                  width="72"
                  height="72"
                  src="/img/info/reliability.png"
                  alt="reliability"
                />
                <span  style={{ marginLeft:'4%'}}>3 роки роботи на ринку</span>
              </li>
            </ul>
           </div>
  
            <div className="mobile-footer">
          
        <div className="footer-link" >
          <Link to='/catalog#top'>  
            <img className="footer-icon " style={{marginLeft:"25%",marginTop:'2%'}} alt="Icon" src="./img/catlg.svg" />
            <div className="footer-text">Каталог</div>
            </Link>
          </div>
            <div className="footer-link">
              <img style={{marginBottom:'1.5px' }} className="footer-icon" alt="Icon" src="./img/love.svg" />
              <div className="footer-text">Список бажань</div>
            </div>
          <div className="footer-link"  onClick={() => setCartOpened(true)}>
            <img className="footer-icon" onClick={() => setCartOpened(true)} alt="Icon" src="./img/cart.png" />
            <div className="footer-text" onClick={() => setCartOpened(true)} >Кошик</div>
          </div>
      </div>


</div>

   <div className="aside">

    <MyComponent/>

    </div>

    </>
  );
}

export default Home;
