function Homes(
  {
setOpen2,
onClose2,

  }) {

  
  return (
    <div
    className="cartOverlay2"
    onClick={(e) => (e.currentTarget === e.target ? onClose2() : onClose2())}
  >
    
    <div className="cart d-flex flex-column">
      <div className="d-flex justify-between mb-25">
        <h2>Додаткова інформація</h2>
        <img
          onClick={() => onClose2(setOpen2(false))   }
          className="removeBtn"
          src="img/btn-remove.svg"
          alt="Close"
        ></img>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div
            style={{
              padding: "10px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            Питання-відвовідь
          </div>
          <div
            style={{
              padding: "10px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
           <h4>Доставка та Оплата</h4>  
          </div>
          <div
            style={{
              padding: "10px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
             <h4>Додаткова інформація</h4>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              padding: "10px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ cursor: "pointer" }}>UA</div>
            <div style={{ cursor: "pointer" }}>Ru</div>
          </div>
          <div style={{ padding: "10px 0" }}>
            <div style={{ fontWeight: "bold" }}>380675929915</div>
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontWeight: "bold" }}>
            <span>Total</span>
            <span className="totalAmount"></span>
          </div>
        </div>
      </div>
    </div>
);
}

export default Homes;