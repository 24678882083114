import { useState, useEffect } from "react";

export const useValidation = (value, validations) => {
  const [isEmpty, setEmptyError] = useState(true);
  const [minLengthError, setMinLengthError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [postNubmerError, setPostNubmerError] = useState(false);

  const [inputValid, setInputValid] = useState(false);

  useEffect(() => {
    for (const validation in validations) {
      // eslint-disable-next-line default-case
      switch (validation) {
        case "minLength":
          value.length < validations[validations]
            ? setMinLengthError(true)
            : setMinLengthError(false);
          break;
        case "isEmpty":
          value ? setEmptyError(false) : setEmptyError(true);
          break;

        case "phoneError":
          const rephone = /((\+)?\b(8|38)?(0[\d]{2}))([\d-]{5,8})([\d]{2})/;
          rephone.test(value) ? setPhoneError(false) : setPhoneError(true);
          break;

        case "nameError":
          const rename = /^[А-ЯЁа-яё '-]+$/i;
          rename.test(value) ? setNameError(false) : setNameError(true);
          break;

        case "surnameError":
          const resurname = /^[А-ЯЁа-яё '-]+$/gim;
          resurname.test(String(value))
            ? setSurnameError(false)
            : setSurnameError(true);
          break;

        case "cityError":
          const recity =
            /^([a-zA-Zа-яА-ЯёЁ]+[-]?[a-zA-Zа-яА-ЯёЁ]*[-]?[a-zA-Zа-яА-ЯёЁ]*[-]?[a-zA-Zа-яА-ЯёЁ]*)$/i;
          recity.test(value) ? setCityError(false) : setCityError(true);
          break;

        case "postNubmerError":
          const repost = /^[0-9]{1,4}$/;
          repost.test(value)
            ? setPostNubmerError(false)
            : setPostNubmerError(true);
          break;
      }
    }
  }, [validations, value]);

  useEffect(() => {
    if (
      isEmpty ||
      phoneError ||
      nameError ||
      surnameError ||
      cityError ||
      postNubmerError
    ) {
      setInputValid(false);
    } else {
      setInputValid(true);
    }
  }, [
    isEmpty,
    phoneError,
    nameError,
    surnameError,
    cityError,
    postNubmerError,
  ]);

  return {
    isEmpty,
    minLengthError,
    phoneError,
    inputValid,
    nameError,
    cityError,
    surnameError,
    postNubmerError,
  };
};
