import { MinusOutlined, PlusOutlined, QuestionOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Space, Switch } from 'antd';
import React from 'react';

const ButtonGroup = Button.Group;


function Card1({title, onAddToCart, onOpen, isAddedToCart,catname,itemCover,price,taste,id}) {

  return (
    <div className="card d-flex flex-column align-center p-10">
      <div className="category d-flex align-center">
        <img src="/img/dot.png" alt="dot"
        // onClick={isAddedToCart(title) ? increase() :  () => onAddToCart()} 
        ></img>
        <span className="ml-5 ">{catname}</span>
      </div>
      <img  className="card-item-image" src={`https://backend.primobar.com.ua${itemCover}`} alt=""></img>
      <h5 style={{display:'flex',justifyContent:'center',marginRight:'20px'}} className="product">{taste}</h5>
      <div className="d-flex align-center justify-between mt-10%" style={{marginTop:'12% !important'}}>
        <p className="d-flex price">
          <b>{price + " грн."}</b>
        </p>
        <img
          style={{
            fill: "black",
height:'38px'          }}
          onClick={isAddedToCart(taste) ? () => onOpen() : () => onAddToCart() }  
          className="plusBtn"
          src = {
            isAddedToCart(taste)
              ? "/img/addedtocart.png "
              : "/img/addtocart.png" || null} 
          alt=""
        ></img>
      </div>
    </div>
  );
}
export default Card1;
