import { Link } from "react-router-dom";
import { useContext,useState } from "react";
import React from 'react';

import CartItem from "./CartItem";
import AppContext from "../context";

function Cart(
  {
    onCartClose,
    onRemove,
    cartItems,
    onQuantityChange,
    quantity,
    newPrice,
  }) {

  return (
    <div
      className="cartOverlay"
      onClick={(e) => (e.currentTarget === e.target ? onCartClose() : 0)}
    >
      
      <div className="cart d-flex flex-column">
        <div className="d-flex justify-between mb-25">
          <h2>Корзина</h2>
          <img
            onClick={() => onCartClose() }
            className="removeBtn"
            src="img/btn-remove.png"
            alt="Close"
          ></img>
        </div>
        <div className="cartItems mb-40" style={{margin:"0 auto"}}>
          
         {
            
          cartItems.length ? (
            cartItems.map((item) => (
            
              <CartItem
              key={item.attributes.taste + item.attributes.title}
              itemCover={item.attributes.img?.data.attributes?.url}
              // itemUrl={item.attributes.img?.data.attributes?.formats.thumbnail?.url}
              price={item.attributes.price}
                onRemove={onRemove}
                title={item.attributes.title}
                cartItems={cartItems}
                quantity={quantity}
                newPrice={item.attributes.price * (quantity)}//
                newQuantity={item.newQuantity}
                item={item}
                onQuantityChange={onQuantityChange}
                value={quantity}
                taste={item.attributes.taste}
             {...item}
            />
            ))
          ) : (
            <>
              <h2>Корзина пуста!</h2>
              <img
                className="empty-wish-list"
                src="/img/empty-wish-list.png"
                alt="wish list is empty!"
              />
            </>
          )}
        </div>

        <div className="d-flex justify-between align-center" >
          <div>
            <span>Итого:</span>
            <span className="fw-bold ml-10">
              {/* {totalPrice} */}
            </span>
          </div>
          <Link to="/order" onClick={() => onCartClose()}>
            <button>Оформить заказ</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Cart;
